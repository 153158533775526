// Tables
th {
  color: $table-th-color;
  font-size: $table-th-size;
  font-weight: $font-weight-semibold;
}

.table {
  border: $table-border;
  border-radius: $border-radius;
}

.table > :not(caption) > * > * {
  padding: $spacer;
}

.table-link {
  text-decoration: none;
  color: $table-link-color;
  font-weight: $table-link-font-weight;
}

.sn-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20rem;

  & th, & td {
      padding: 1rem
  }
  
  &.table-bordered {
    & > thead,
    & > thead > tr,
    & > tbody,
    & > tbody > tr:last-child {
      border-radius: inherit;
    }

    & > thead > tr > th:first-child {
      border-top-left-radius: inherit;
    }
    
    & > thead > tr > th:last-child {
      border-top-right-radius: inherit;
    }

    & > tbody > tr:last-child > td:first-child {
      border-bottom-left-radius: inherit;
    }
    
    & > tbody > tr:last-child > td:last-child {
      border-bottom-right-radius: inherit;
    }
    
    & th, & td {
        border-width: 0 0px 1px 0;
        
        &:last-child {
            border-right-width: 0;
        }
    }
    
    & tbody > tr:last-child > td {
        border-bottom-width: 0
    }
  }

  &.align-cells-start {
    & th, & td {
      text-align: left;
    }
  }
}
