.reply-textarea {
  height: 150px;
  border: none;
  width: 100%;
}

.reply-textarea:focus {
  outline: none !important;
}

.reply-container {
  border: var(--bs-border-width) solid #CED4DA;
  border-radius: var(--bs-border-radius);
  background-color: white;
  margin-bottom: 0.75rem;
}

.reply-header {
  border-bottom: 1px solid black;
}

.reply-footer {
  border-top: 1px solid black;
}

.chat {
  top: 0px !important;
}
